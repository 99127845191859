import { green } from '@mui/material/colors';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/unoHR/logo.png';
import contact from '../Contact/Forms'

function FooterHomeOne({ className }) {
    return (
        <>
            <section id="footer" className={`unoHR-footer-area ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-about-widget">
                            <div className="unoHR-logo-box">
                                    <a href="/">
                                    <img src={logo} alt="" style={{width: '17vh', height: '6vh'}} />
                                    </a>
                              <p style={{color: 'grey' , fontSize:'13px'}}>
                                    helpara is all-in-one innovative, simple to houshold employees management software that
                                    helps employers with all the features needed to manage onboarding, background checks, 
                                    task manager, timesheets, payroll and employee rewards. Our cloud bases platform is 
                                    easy to use and can be run entirely from your mobile phone (IOS & Android). Alerts and
                                    notifications, makes it easier not to miss daily tasks & deadlines.
                                </p> </div>
                             
                            </div>
                        
                        </div>

                        
                    
                        <div className="col-lg-2 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">Support</h4>
                                <ul>
                                    <li>
                                        <Link to="#features">Features</Link>
                                    </li>
                                    <li>
                                        <a href="#pricings">Pricing</a>
                                    </li>
                                    <li>
                                        <a href="#faq">FAQ</a>
                                    </li>
                                    <li>
                                        <a href="#footer">Contact</a>
                                    </li>
                                    <li>
                                        <a href="#">Login</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6" >
                            <div className="footer-widget-info">
                                <h4 className="title">Contact</h4>
                               
                                    
<div class="text-center mx-auto g-width-70x--md">

  <form>
    <div class="row">
      <div class="col-md-6 form-group g-mb-30">
        <input class="form-control g-color-gray-dark-v5 g-bg-white g-bg-white--focus g-brd-gray-light-v4 g-brd-primary--focus g-rounded-20 g-py-13 g-px-15" type="text" placeholder="Your Name"/>
      </div>

      <div class="col-md-6 form-group g-mb-30">
        <input class="form-control g-color-gray-dark-v5 g-bg-white g-bg-white--focus g-brd-gray-light-v4 g-brd-primary--focus g-rounded-20 g-py-13 g-px-15" type="email" placeholder="Your Email"/>
      </div>

      <div class="col-md-12 form-group g-mb-30">
        <input class="form-control g-color-gray-dark-v5 g-bg-white g-bg-white--focus g-brd-gray-light-v4 g-brd-primary--focus g-rounded-20 g-py-13 g-px-15" type="text" placeholder="Subject"/>
      </div>

      <div class="col-md-12 form-group g-mb-30">
        <textarea class="form-control g-color-gray-dark-v5 g-bg-white g-bg-white--focus g-brd-gray-light-v4 g-brd-primary--focus g-resize-none g-rounded-20 g-py-13 g-px-15" rows="7" placeholder="Your Comments"></textarea>
      </div>
    </div>

    <div class="text-center">
      <button class="btn btn-success text-uppercase g-rounded-30 g-px-25 g-py-13" type="submit" role="button">Send Message</button>
    </div>
  </form>
</div>
                              
                              
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                              
                                <div className="copyright-text" style={{color: 'grey'}}>
                                    <p>helpara is registered trademarks of WorkScaler Inc</p>
                                    <p> <ul><Link className='link' to='/thirdparty'>© 2022 WorkScaler inc. | All third party trademarks are the property of their respective owners.</Link></ul></p>
                                    
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                              
                                <div className="copyright-text2" style={{color:'grey', paddingLeft: '220px', marginTop: '25px'}}>
                                <ul><Link to="/caprivacy" style={{color:'grey'}}> CA Privacy Rights</Link></ul>
                                <ul>|</ul>
                                <ul><Link to="/privacypolicy" style={{color:'grey'}}>  Privacy Policy</Link></ul>
                                <ul>|</ul>
                                <ul><Link to="/Terms" style={{color:'grey'}}>  Terms and Service</Link></ul>
                               
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </section>
        </>
    );
}

export default FooterHomeOne;
